import React, { useEffect, useRef, useState } from "react";
import { Social } from "../socials/socials";
import { Layout } from "../layout";
import { ExperienceItem } from "../../data/experience";
import {
  EmploymentCardContainer,
  Title,
  Responsibility,
  ExpCarousel,
  Line,
  Divider,
  LineItem,
  ListDetail,
  ExpContainer,
  SocialContainer,
  Header,
} from "../../styles/web/webExperience.styles";

export const EmploymentCard = ({ employment }) => {
  return (
    <EmploymentCardContainer>
      <Header>{employment.name}</Header>
      <Title>{employment.position}</Title>
      <Title>{employment.year}</Title>
      <Title>{employment.location}</Title>
      <Responsibility>
        {employment.responsibilities.map((resp) => {
          return <li>{resp}</li>;
        })}
      </Responsibility>
    </EmploymentCardContainer>
  );
};

export const CenterMode = ({ index, setCurrentSlide }) => {
  return (
    <ExpCarousel
      wrapAround={true}
      renderCenterLeftControls={({ previousSlide }) => (
        <button
          onClick={() => {
            previousSlide();
            setCurrentSlide(
              index === 0 ? ExperienceItem.length - 1 : index - 1
            );
          }}
        >
          {"<"}
        </button>
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <button
          onClick={() => {
            nextSlide();
            setCurrentSlide(
              index === ExperienceItem.length - 1 ? 0 : index + 1
            );
          }}
        >
          {">"}
        </button>
      )}
      speed={1000}
      renderBottomCenterControls={null}
      dragging={false}
    >
      {ExperienceItem.map((item) => {
        return <EmploymentCard employment={item} />;
      })}
    </ExpCarousel>
  );
};

export const TimeLine = ({ index, setLoading }) => {
  const [loaders, setLoaders] = useState<boolean[]>(
    new Array(ExperienceItem.length).fill(false)
  );

  useEffect(() => {
    let checker = (arr) => arr.every(Boolean);
    if (checker) setLoading(false);
  }, [loaders]);

  return (
    <Line index={index}>
      <Divider />
      {ExperienceItem.map((item, idx) => {
        return (
          <>
            <LineItem>
              <ListDetail>
                <img
                  src={item.logo}
                  onLoad={() => {
                    let loadersCopy = [...loaders];
                    loadersCopy[idx] = true;
                    setLoaders(loadersCopy);
                  }}
                  loading={"eager"}
                />
              </ListDetail>
            </LineItem>
            <Divider />
          </>
        );
      })}
    </Line>
  );
};

export const WebExperience = () => {
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = React.useState(true);
  const setCurrentSlide = (slideIndex: number) => {
    setIndex(slideIndex);
  };
  return (
    <Layout displayLoader={loading}>
      <>
        <ExpContainer>
          <TimeLine index={index} setLoading={setLoading} />
          <CenterMode index={index} setCurrentSlide={setCurrentSlide} />
        </ExpContainer>
        <SocialContainer>
          <Social alignCenter={true} />
        </SocialContainer>
      </>
    </Layout>
  );
};
